import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "./Header.css"; // Optional custom CSS for styling
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const openMenu = () => {
    setIsOpen(true);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  };

  React.useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let MobileMenu = () => {
    return (
      <>
        {/* <div className="top-bar">
                <div className="container">
                    <p className="message">Admission / Enquiry opened for BSc Nursing</p>
                    <a className="btn" href="/admission">Click here to apply for the required course</a>
                </div>
            </div> */}

        <header className="bg-light py-2">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Link to="/">
                  <img
                    src="./images/sen-logo.png"
                    alt="Logo"
                    className="img-fluid"
                    style={{ height: "50px", float: "left" }}
                  />
                </Link>
              </div>
              <div className="text-center flex-grow-1">
                <Link to="/">
                  <h4
                    className="m-0 text-dark"
                    style={{
                      textAlign: "left",
                      textTransform: "uppercase",
                    }}
                  >
                    Sophia College of Nursing <br />
                    <span style={{ fontSize: "16px" }}>Tumkur, Karnataka</span>
                  </h4>
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                onClick={openMenu}
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars" style={{ fontSize: 30 }} />
              </button>
            </div>
          </div>
        </header>
        <div
          className={`offcanvas-end ${isOpen ? "open" : ""}`}
          id="rightDrawer"
        >
          <button className="close-btn" onClick={closeMenu}>
            &times;
          </button>

          <ul className="navbar-nav ms-auto mt-5">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#dropdown"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Our Course
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/bscnursing">
                    BSC Nursing
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/facilities">
                Our Facilities
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/campus">
                Our Campus
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admission">
                Admission
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/photos">
                Photos
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/career">
                Career
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  };

  let DesktopMenu = () => {
    return (
      <>
        <header className="bg-light py-2">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Link to="/">
                  <img
                    src="./images/sen-logo.png"
                    alt="Logo"
                    className="img-fluid"
                    style={{ height: "100px", float: "left" }}
                  />
                </Link>
              </div>
              <div className="text-center flex-grow-1">
                <Link to="/">
                  <h4
                    className="m-0 text-dark"
                    style={{
                      textAlign: "center",
                      fontWeight: "bolder",
                      fontSize: "24px",
                      textTransform: "uppercase",
                    }}
                  >
                    Sophia College of Nursing <br />
                    <span style={{ fontSize: "16px" }}>Tumkur, Karnataka</span>
                  </h4>
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                onClick={openMenu}
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars" style={{ fontSize: 30 }} />
              </button>
            </div>
          </div>
        </header>
        <div
          className={`offcanvas-end ${isOpen ? "open" : ""}`}
          id="rightDrawer"
        >
          <button className="close-btn" onClick={closeMenu}>
            &times;
          </button>
          <ul className="navbar-nav ms-auto mt-5">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/about">
                About
              </Link>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#dropdown"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Our Course
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <Link className="dropdown-item" to="/bscnursing">
                    BSC Nursing
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/facilities">
                Our Facilities
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/campus">
                Our Campus
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/admission">
                Admission
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/photos">
                Photos
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/career">
                Career
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to="/Contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  };
  return <>{isMobileView ? <MobileMenu /> : <DesktopMenu />}</>;
};

export default Header;
